import React from 'react'
import { graphql } from 'gatsby'
import { PrismicPage } from '../../graphql-types'
import { usePreviewData } from '../hooks/usePreview'
import { Page } from '../components/page'

interface PageTemplateProps {
  data: {
    prismicPage?: PrismicPage
  }
}

function PageTemplate({ data: staticData }: PageTemplateProps) {
  const data = usePreviewData(staticData)

  return <Page page={data?.prismicPage as PrismicPage} />
}

export default PageTemplate

export const PageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      ...PrismicPage
    }
  }
`

export const prismicPageBodyMediaItemTypeFragment = graphql`
  fragment PrismicPageBodyMediaItemType on PrismicPageBodyMediaItemType {
    image {
      alt
      copyright
      dimensions {
        height
        width
      }
      fluid(
        srcSetBreakpoints: [200, 400, 600, 800, 1200, 1600, 2000, 2400, 2800]
      ) {
        ...GatsbyPrismicImageFluid
      }
      thumbnails
      url
    }
    text {
      html
      raw
      text
    }
    text_alignment
    text_color
    text_position
    text_size
  }
`

export const prismicPageBodyMediaFragment = graphql`
  fragment PrismicPageBodyMedia on PrismicPageBodyMedia {
    id
    items {
      ...PrismicPageBodyMediaItemType
    }
    primary {
      align_items
      centered
    }
  }
`

export const prismicPageFragment = graphql`
  fragment PrismicPage on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyMedia {
          ...PrismicPageBodyMedia
        }
      }
      background_color
      meta_description
      title
    }
    id
    prismicId
    uid
  }
`
