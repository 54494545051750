import React, { memo } from 'react'
import Img, { FluidObject, GatsbyImageProps } from 'gatsby-image'
import {
  PrismicPageBodyMediaItemType,
  PrismicProjectBodyMediaItemType,
} from '../../graphql-types'

function SliceItem({
  item,
  siblings,
  width,
}: {
  item: PrismicPageBodyMediaItemType & PrismicProjectBodyMediaItemType
  siblings: number
  width: string
}) {
  // If we have preview data, it will be directly on image1 rather than on localFile
  const fluid: FluidObject | undefined = item?.image?.fluid
    ? (item.image.fluid as FluidObject)
    : (item?.image?.localFile?.childImageSharp?.fluid as FluidObject)

  if (fluid) {
    if (item.text_position === `Right` || item.text_position === `Left`) {
      // This is actually smaller than L/R images without adjacent text,
      // since they have flex-auto and grow. So this could create a quality issue.
      fluid.sizes = `
        (min-width: 640px) calc(((100vw - 2rem) / ${siblings} - 3rem) * 2 / 3),
        ${
          siblings === 1
            ? `(min-width: 68rem) calc((64rem - 1rem) * 2 / 3),`
            : ``
        }
        calc((100vw - 3rem) * 2 / 3)
      `
    } else {
      fluid.sizes = `
        (min-width: 640px) calc((100vw - 2rem) / ${siblings} - 2rem),
        (min-width: 1024px) calc((100vw - 2rem) * ${width} / ${siblings} - 2rem),
        ${siblings === 1 ? `(min-width: 68rem) 64rem,` : ``}
        calc(100vw - 2rem)
      `
    }
  }

  const imageProps: GatsbyImageProps = {
      placeholderStyle: {
        objectFit: `cover`,
      },
      fluid: fluid,
      fadeIn: false,
    },
    textHTML = item?.text?.text && item?.text?.html,
    textSizeClass = `text-${
      item?.text_size?.toLowerCase() ?? `xs`
    } leading-normal`,
    textAlignClass =
      item?.text_alignment === `Center`
        ? `text-${item?.text_alignment?.toLowerCase()}`
        : ``,
    textStyle = item?.text_color ? { color: item?.text_color } : {},
    noImageOrText = !fluid && !textHTML,
    noImageOrTextClass = noImageOrText
      ? `hidden ${siblings > 3 ? `md:block` : `sm:block`}`
      : ``

  switch (item.text_position) {
    case 'Top':
      return (
        <div
          className={`flex-initial w-full max-w-5xl m-4 leading-0 ${noImageOrTextClass}`}
          data-siblings={siblings}
        >
          <div
            className={`${textAlignClass} ${textSizeClass}`}
            style={textStyle}
            dangerouslySetInnerHTML={{ __html: textHTML || `` }}
          />
          {fluid && (
            <Img
              className={`inline-block w-full ${textHTML && 'mt-2'}`}
              {...imageProps}
            />
          )}
        </div>
      )
    case 'Right':
      return (
        <div
          className={`flex-initial inline-flex flex-row justify-center w-full max-w-5xl m-4 ${noImageOrTextClass}`}
          data-siblings={siblings}
          style={{
            alignItems: `inherit`,
          }}
        >
          {fluid && (
            <Img
              className={`flex-auto inline-block w-2/3 ${textHTML && 'mr-4'}`}
              {...imageProps}
            />
          )}
          <div
            className={`flex-auto inline-block ${textAlignClass} ${textSizeClass} max-w-1/3`}
            style={textStyle}
            dangerouslySetInnerHTML={{ __html: textHTML || `` }}
          />
        </div>
      )
    case `Left`:
      return (
        <div
          className={`flex-initial inline-flex flex-row justify-center w-full max-w-5xl m-4 ${noImageOrTextClass}`}
          data-siblings={siblings}
          style={{
            alignItems: `inherit`,
          }}
        >
          <div
            className={`flex-auto inline-block max-w-1/3 ${textAlignClass} ${textSizeClass}`}
            style={textStyle}
            dangerouslySetInnerHTML={{ __html: textHTML || `` }}
          />
          {fluid && (
            <Img
              className={`flex-auto inline-block w-2/3 ${textHTML && 'ml-4'}`}
              {...imageProps}
            />
          )}
        </div>
      )
    case 'Bottom':
    default:
      return (
        <div
          className={`flex-initial w-full max-w-5xl m-4 leading-0 ${noImageOrTextClass}`}
          data-siblings={siblings}
        >
          {fluid && (
            <Img
              className={`inline-block w-full ${textHTML && 'mb-2'}`}
              {...imageProps}
            />
          )}
          <div
            className={`${textAlignClass} ${textSizeClass}`}
            style={textStyle}
            dangerouslySetInnerHTML={{ __html: textHTML || `` }}
          />
        </div>
      )
  }
}

const SliceItemMemo = memo(SliceItem)

export default SliceItemMemo
export { SliceItemMemo as SliceItem }
