import { useEffect } from 'react'
import { useLocation } from '@reach/router'

function useBackgroundColor(backgroundColor?: string | null) {
  const { pathname } = useLocation()

  useEffect(() => {
    if (!backgroundColor || !pathname) return
    const id = pathname.substr(1).replace(/\//, ``)
    if (document.querySelector(`#${id}`)) return

    const style = document.createElement(`style`),
      content = document.createTextNode(
        `html[data-pathname="${pathname}"] { background-color: ${backgroundColor}; }`
      )

    style.id = id
    style.type = `text/css`
    style.appendChild(content)
    document.head.appendChild(style)
  }, [backgroundColor, pathname])
}

export { useBackgroundColor }
