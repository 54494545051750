import React, { memo } from 'react'
import { PrismicProjectBodyMedia } from '../../graphql-types'
import { SliceItem } from './sliceItem'

interface SliceProps {
  index: number
  siblings: number
  slice: PrismicProjectBodyMedia
}

const ProjectSlice = ({ index, siblings, slice }: SliceProps) => {
  const alignItems = slice?.primary?.align_items,
    alignItemsClass =
      alignItems === `Top`
        ? `items-start`
        : alignItems === `Bottom`
        ? `items-end`
        : `items-center`
  let centeredClass = ``,
    containerWidth = `1`

  if (slice?.primary?.centered) {
    centeredClass += `mx-auto centered `

    if (slice?.items?.length === 1 && slice?.items[0]?.image?.fluid) {
      centeredClass += `lg:max-w-1/2`
      containerWidth = `1 / 2`
    } else {
      centeredClass += `lg:max-w-3/4`
      containerWidth = `3 / 4`
    }
  }

  return (
    <div
      className={`ProjectSlice ${
        index + 1 !== siblings ? 'mb-16 lg:mb-8' : ''
      } flex flex-wrap sm:flex-no-wrap justify-center ${alignItemsClass} ${centeredClass}`}
      data-children={slice?.items?.length}
    >
      {slice?.items?.map((item: any, i: number, a: any[]) => {
        if (item === null) return <></>
        return (
          <SliceItem
            key={`${item.id}-${i}`}
            item={item}
            width={containerWidth}
            siblings={a.length}
          />
        )
      })}
    </div>
  )
}

const ProjectSliceMemo = memo(ProjectSlice)

export default ProjectSliceMemo
export { ProjectSliceMemo as ProjectSlice }
