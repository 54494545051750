import React from 'react'
import { PrismicPage } from '../../graphql-types'
import { useBackgroundColor } from '../hooks/useBackgroundColor'
import { SEO } from './seo'
import { ProjectSlice } from './projectSlice'

const Page = ({ page }: { page: PrismicPage | undefined }) => {
  const data = page?.data,
    backgroundColor = data?.background_color

  useBackgroundColor(backgroundColor)

  return (
    <div className="page min-h-screen">
      <SEO type="page" uid={page?.uid} data={data} />
      <div className="pb-24 lg:p-24">
        {data?.body?.map((slice, index, array) =>
          slice ? (
            <ProjectSlice
              key={slice.id}
              slice={slice}
              index={index}
              siblings={array.length}
            />
          ) : null
        )}
      </div>
    </div>
  )
}

export default Page
export { Page }
